@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/Rubik-Light.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/Rubik-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Rubik-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/Rubik-RegularItalic.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Rubik-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/Rubik-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Rubik-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 600;
  src: url("../assets/fonts/Rubik-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Rubik-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/Rubik-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/Rubik-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 800;
  src: url("../assets/fonts/Rubik-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/Rubik-Black.ttf") format("truetype");
}
@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 900;
  src: url("../assets/fonts/Rubik-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Aquire";
  font-style: normal;
  font-display: auto;
  src: url(../assets/fonts/Aquire.otf);
}
@font-face {
  font-family: "Aquire";
  font-style: bold;
  font-display: auto;
  src: url(../assets/fonts/AquireBold.otf);
}
