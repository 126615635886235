.loading_container {
  position: absolute;
  width: 76px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading_container img {
  width: 76px;
}
