// Variables

// Color System

$bg-elevation-0: #181924 !default;
$bg-elevation-1: rgba(mix(rgba(255, 255, 255, 0.1), $bg-elevation-0), 1) !default;
$bg-elevation-2: rgba(mix(rgba(255, 255, 255, 0.14), $bg-elevation-0), 1) !default;
$bg-elevation-3: rgba(mix(rgba(255, 255, 255, 0.16), $bg-elevation-0), 1) !default;
$bg-elevation-4: rgba(mix(rgba(255, 255, 255, 0.18), $bg-elevation-0), 1) !default;
$bg-elevation-6: rgba(mix(rgba(255, 255, 255, 0.22), $bg-elevation-0), 1) !default;
$bg-elevation-8: rgba(mix(rgba(255, 255, 255, 0.24), $bg-elevation-0), 1) !default;
$bg-elevation-12: rgba(mix(rgba(255, 255, 255, 0.28), $bg-elevation-0), 1) !default;
$bg-elevation-16: rgba(mix(rgba(255, 255, 255, 0.3), $bg-elevation-0), 1) !default;
$bg-elevation-24: rgba(mix(rgba(255, 255, 255, 0.32), $bg-elevation-0), 1) !default;

$white: #fff !default;
$gray-base: #3c4b64 !default;
$gray-100: #ebedef !default;
$gray-200: #d8dbe0 !default;
$gray-300: #c4c9d0 !default;
$gray-400: #b1b7c1 !default;
$gray-500: #9da5b1 !default;
$gray-600: #8a93a2 !default;
$gray-700: #768192 !default;
$gray-800: #636f83 !default;
$gray-900: #4f5d73 !default;
$black: #000015 !default;

$high-emphasis: rgba($white, 0.87) !default;
$medium-emphasis: rgba($white, 0.6) !default;
$disabled: rgba($white, 0.38) !default;

$high-emphasis-inverse: rgba($white, 0.87) !default;
$medium-emphasis-inverse: rgba($white, 0.6) !default;
$disabled-inverse: rgba($white, 0.38) !default;

$light-pre: $gray-100 !default;
$dark-pre: $gray-900 !default;

$primary-base: #321fdb !default;
$secondary-base: #9da5b1 !default;
$success-base: #2eb85c !default;
$info-base: #39f !default;
$warning-base: #f9b115 !default;
$danger-base: #e55353 !default;
$light-base: rgba($white, 0.1) !default;
$dark-base: rgba($black, 0.1) !default;

$primary: tint-color(desaturate($primary-base, 30%), 30) !default;
$secondary: desaturate($secondary-base, 30%) !default;
$success: desaturate($success-base, 30%) !default;
$info: desaturate($info-base, 30%) !default;
$warning: desaturate($warning-base, 30%) !default;
$danger: desaturate($danger-base, 30%) !default;
$light: $light-base !default;
$dark: $dark-base !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
) !default;

$primary-gradient: (
  "start": desaturate(#321fdb, 20%),
  "stop": desaturate(#1f1498, 20%),
) !default;

$secondary-gradient: (
  "start": desaturate(#c8d2dc, 20%),
  "stop": desaturate($white, 20%),
) !default;

$light-gradient: (
  "start": desaturate(#e3e8ed, 20%),
  "stop": desaturate($white, 20%),
) !default;

$dark-gradient: (
  "start": desaturate(#3c4b64, 20%),
  "stop": desaturate(#212333, 20%),
) !default;

$danger-gradient: (
  "start": desaturate(#e55353, 20%),
  "stop": desaturate(#d93737, 20%),
) !default;

$warning-gradient: (
  "start": desaturate(#f9b115, 20%),
  "stop": desaturate(#f6960b, 20%),
) !default;

$success-gradient: (
  "start": desaturate(#2eb85c, 20%),
  "stop": desaturate(#1b9e3e, 20%),
) !default;

$info-gradient: (
  "start": desaturate(#39f, 20%),
  "stop": desaturate(#2982cc, 20%),
) !default;

$theme-gradients: (
  "primary": $primary-gradient,
  "secondary": $secondary-gradient,
  "success": $success-gradient,
  "info": $info-gradient,
  "warning": $warning-gradient,
  "danger": $danger-gradient,
  "light": $light-gradient,
  "dark": $dark-gradient,
) !default;

// Body

$body-bg: $bg-elevation-0 !default;
$body-color: $high-emphasis !default;
$elevation-base-color: 0, 0, 0 !default;
$border-color: rgba(255, 255, 255, 0.075) !default;

// Component

$component-active-color: $white !default;
$component-active-bg: $primary !default;

$min-contrast-ratio: 4.5 !default;

$color-contrast-dark: $high-emphasis-inverse !default;
$color-contrast-light: $high-emphasis !default;

// Typography

$font-family-sans-serif: "Rubik", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
  "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: "Rubik", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

// Accordion

$accordion-button-color: $body-color !default;
$accordion-button-bg: transparent !default;
$accordion-border-color: rgba($white, 0.125) !default;
$accordion-bg: transparent !default;

$accordion-button-color: $body-color !default;
$accordion-button-bg: transparent !default;
$accordion-border-color: rgba($white, 0.125) !default;

$accordion-button-active-bg: tint-color(rgba($component-active-bg, 0.5), 5%) !default;
$accordion-button-active-color: shade-color($component-active-color, 10%) !default;

$accordion-button-focus-border-color: rgba($white, 0.2) !default;

$accordion-icon-color: $body-color !default;
$accordion-icon-active-color: $body-color !default;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

// Alert

$alert-bg-scale: -80% !default;
$alert-border-scale: -70% !default;
$alert-color-scale: 40% !default;

$alert-variants: (
  "primary": alert-color-map($primary),
  "secondary": alert-color-map($secondary),
  "success": alert-color-map($success),
  "danger": alert-color-map($danger),
  "warning": alert-color-map($warning),
  "info": alert-color-map($info),
  "light": alert-color-map($light-pre),
  "dark": alert-color-map($dark-pre),
) !default;

// Breadcrumb

$breadcrumb-bg: null !default;
$breadcrumb-divider-color: $body-color !default;
$breadcrumb-active-color: $medium-emphasis !default;

// Buttons

$btn-hover-bg-shade-amount: 15% !default;
$btn-hover-bg-tint-amount: 15% !default;
$btn-hover-border-shade-amount: 20% !default;
$btn-hover-border-tint-amount: 10% !default;
$btn-active-bg-shade-amount: 20% !default;
$btn-active-bg-tint-amount: 20% !default;
$btn-active-border-shade-amount: 25% !default;
$btn-active-border-tint-amount: 10% !default;

$button-variants: (
  "primary": btn-color-map($primary, $primary),
  "secondary": btn-color-map($secondary, $secondary),
  "success": btn-color-map($success, $success),
  "danger": btn-color-map($danger, $danger),
  "warning": btn-color-map($warning, $warning),
  "info": btn-color-map($info, $info),
  "light": btn-color-map($light-pre, $light-pre),
  "dark": btn-color-map($dark-pre, $dark-pre),
) !default;

$button-outline-ghost-variants: (
  "primary": btn-outline-color-map($primary),
  "secondary": btn-outline-color-map($secondary),
  "success": btn-outline-color-map($success),
  "danger": btn-outline-color-map($danger),
  "warning": btn-outline-color-map($warning),
  "info": btn-outline-color-map($info),
  "light": btn-outline-color-map($light-pre),
  "dark": btn-outline-color-map($dark-pre),
) !default;

// Cards

$card-bg: $bg-elevation-1 !default;
$card-color: null !default;
$card-border-color: $border-color !default;
$card-cap-bg: $bg-elevation-2 !default;
$card-cap-color: null !default;

// Close button

$btn-close-color: $high-emphasis !default;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;

// Dropdown

$dropdown-color: $body-color !default;
$dropdown-bg: $bg-elevation-8 !default;
$dropdown-border-color: $border-color !default;
$dropdown-divider-bg: $border-color !default;
$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: $body-color !default;
$dropdown-link-hover-bg: $bg-elevation-16 !default;
$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;
$dropdown-link-disabled-color: rgba($body-color, 0.6) !default;
$dropdown-header-color: $body-color !default;

// Footer

$footer-bg: $bg-elevation-3 !default;
$footer-color: $body-color !default;
$footer-border-width: 0 !default;
$footer-border-color: transparent !default;

// Forms

$input-color: $body-color !default;
$input-bg: rgba($white, 0.05) !default;
$input-border-color: rgba($white, 0.09) !default;
$input-focus-color: $body-color !default;
$input-focus-bg: rgba($white, 0.05) !default;
$input-focus-border-color: rgba($white, 0.2) !default;
$input-disabled-bg: rgba($white, 0.1) !default;
$input-placeholder-color: $medium-emphasis !default;
$input-plaintext-color: $body-color !default;

$form-text-color: $medium-emphasis !default;

$input-group-addon-color: $body-color !default;
$input-group-addon-bg: rgba($white, 0.09) !default;
$input-group-addon-border-color: rgba($white, 0.09) !default;

$form-select-color: $input-color !default;
$form-select-bg: $input-bg !default;
$form-select-border-color: $input-border-color !default;
$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-disabled-bg: $input-disabled-bg !default;

$form-check-input-checked-color: $component-active-color !default;
$form-check-input-checked-bg-color: $component-active-bg !default;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color !default;

$form-multi-select-color: $input-color !default;
$form-multi-select-bg: $input-bg !default;
$form-multi-select-border-color: $input-border-color !default;

$form-multi-select-focus-color: $input-focus-color !default;
$form-multi-select-focus-bg: $input-focus-bg !default;
$form-multi-select-focus-border-color: $input-focus-border-color !default;

$form-multi-select-disabled-color: $disabled !default;
$form-multi-select-disabled-bg: $input-disabled-bg !default;

$form-multi-select-tag-bg: $light !default;
$form-multi-select-tag-border-color: $input-border-color !default;

$form-multi-select-search-color: $input-color !default;
$form-multi-select-search-bg: transparent !default;
$form-multi-select-search-border-color: $input-border-color !default;

$form-multi-select-cleaner-color: $medium-emphasis !default;
$form-multi-select-cleaner-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$form-multi-select-cleaner-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$form-multi-select-cleaner-hover-color: $high-emphasis !default;
$form-multi-select-cleaner-hover-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$form-multi-select-cleaner-hover-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$form-multi-select-cleaner-focus-color: $high-emphasis !default;
$form-multi-select-cleaner-focus-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$form-multi-select-cleaner-focus-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$form-multi-select-cleaner-border-color: $input-border-color !default;

$form-multi-select-select-all-color: $medium-emphasis !default;
$form-multi-select-select-all-bg: transparent !default;
$form-multi-select-select-all-hover-color: $high-emphasis !default;
$form-multi-select-select-all-hover-bg: transparent !default;
$form-multi-select-select-all-border-color: $input-border-color !default;

$form-multi-select-options-color: $body-color !default;
$form-multi-select-options-bg: $bg-elevation-8 !default;
$form-multi-select-options-border-color: $input-border-color !default;

$form-multi-select-option-hover-color: $body-color !default;
$form-multi-select-option-hover-bg: $light !default;

$form-multi-select-option-selected-bg: $light !default;

// Header

$header-bg: $bg-elevation-3 !default;
$header-color: rgba($white, 0.75) !default;
$header-border: (
  bottom: 0,
) !default;
$header-hover-color: rgba($white, 0.9) !default;
$header-active-color: $white !default;
$header-disabled-color: rgba($white, 0.25) !default;
$header-brand-color: $header-active-color !default;
$header-brand-hover-color: $header-active-color !default;
$header-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$header-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$header-toggler-hover-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$header-hover-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$header-toggler-border-color: rgba($white, 0.1) !default;
$header-divider-border-color: rgba($white, 0.1) !default;

// Links

$link-color: shift-color($primary, -12) !default;
$link-hover-color: shift-color($link-color, -20) !default;

// List group

$list-group-item-bg-scale: -80% !default;
$list-group-item-color-scale: 40% !default;

$list-group-color: $body-color !default;
$list-group-bg: transparent !default;
$list-group-border-color: $border-color !default;
$list-group-hover-bg: rgba($white, 0.1) !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;
$list-group-disabled-color: rgba($body-color, 0.5) !default;
$list-group-disabled-bg: rgba($border-color, 0.8) !default;
$list-group-action-color: $body-color !default;
$list-group-action-hover-color: $body-color !default;
$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-900 !default;

$list-group-variants: (
  "primary": list-group-color-map($primary),
  "secondary": list-group-color-map($secondary),
  "success": list-group-color-map($success),
  "danger": list-group-color-map($danger),
  "warning": list-group-color-map($warning),
  "info": list-group-color-map($info),
) !default;

// Modals

$modal-content-color: null !default;
$modal-content-bg: $bg-elevation-24 !default;
$modal-content-border-color: rgba(0, 0, 0, 0.2) !default;
$modal-header-border-color: rgba(0, 0, 0, 0.2) !default;
$modal-footer-border-color: rgba(0, 0, 0, 0.2) !default;

// Navs

$nav-link-color: null !default;
$nav-link-disabled-color: $disabled !default;
$nav-link-hover-color: null !default;
$nav-pills-link-active-bg: $component-active-bg !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-tabs-border-color: $border-color !default;
$nav-tabs-link-active-bg: $border-color !default;
$nav-tabs-link-active-color: $body-color !default;
$nav-tabs-link-active-border-color: transparent !default;
$nav-tabs-link-hover-border-color: $border-color $border-color $border-color !default;

$nav-underline-border-color: $border-color !default;
$nav-underline-link-color: $medium-emphasis !default;
$nav-underline-link-active-color: $component-active-bg !default;
$nav-underline-link-active-border-color: $component-active-bg !default;

// Pagination

$pagination-color: $link-color !default;
$pagination-bg: transparent !default;
$pagination-border-color: $border-color !default;

$pagination-focus-color: $link-hover-color !default;
$pagination-focus-bg: $border-color !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $border-color !default;
$pagination-hover-border-color: $border-color !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: $disabled !default;
$pagination-disabled-bg: transparent !default;
$pagination-disabled-border-color: $border-color !default;

// Popovers

$popover-bg: $bg-elevation-16 !default;
$popover-border-color: $border-color !default;
$popover-header-bg: $bg-elevation-16 !default;
$popover-header-color: $body-color !default;
$popover-body-color: $body-color !default;
$popover-arrow-color: $popover-bg !default;
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;

// Progress

$progress-bg: rgba($white, 0.2) !default;
$progress-bar-color: $white !default;
$progress-bar-bg: $primary !default;

// Sidebar

$sidebar-color: $body-color !default;
$sidebar-bg: $bg-elevation-3 !default;
$sidebar-border-width: 0 !default;
$sidebar-border-color: transparent !default;
$sidebar-brand-color: inherit !default;
$sidebar-brand-bg: rgba($black, 0.2) !default;
$sidebar-footer-bg: rgba($black, 0.2) !default;
$sidebar-header-bg: rgba($black, 0.2) !default;
$sidebar-toggler-bg: rgba($black, 0.2) !default;
$sidebar-toggler-hover-bg: rgba($black, 0.3) !default;
$sidebar-nav-title-color: $medium-emphasis !default;
$sidebar-nav-link-color: $medium-emphasis !default;
$sidebar-nav-link-bg: transparent !default;
$sidebar-nav-link-icon-color: $medium-emphasis !default;
$sidebar-nav-link-hover-color: $high-emphasis !default;
$sidebar-nav-link-hover-bg: rgba($white, 0.05) !default;
$sidebar-nav-link-hover-icon-color: $high-emphasis !default;

$sidebar-nav-link-active-color: $high-emphasis !default;
$sidebar-nav-link-active-bg: rgba($white, 0.05) !default;
$sidebar-nav-link-active-icon-color: $high-emphasis !default;

$sidebar-nav-link-disabled-color: $disabled !default;
$sidebar-nav-link-disabled-icon-color: $sidebar-nav-link-icon-color !default;

$sidebar-nav-group-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-nav-group-toggle-show-color: $sidebar-nav-link-color !default;

$sidebar-nav-group-indicator-color: $medium-emphasis !default;
$sidebar-nav-group-indicator-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$sidebar-nav-group-indicator-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$sidebar-nav-group-indicator-hover-color: $sidebar-nav-link-hover-color !default;
$sidebar-nav-group-indicator-hover-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$sidebar-nav-group-indicator-hover-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

// Tables

$table-bg-scale: -80% !default;
$table-border-factor: 0.1 !default;

$table-color: $body-color !default;
$table-bg: transparent !default;
$table-border-color: $border-color !default;

$table-striped-color: $table-color !default;
$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($white, $table-striped-bg-factor) !default;

$table-active-color: $table-color !default;
$table-active-bg-factor: 0.1 !default;
$table-active-bg: rgba($black, $table-active-bg-factor) !default;

$table-hover-color: $table-color !default;
$table-hover-bg-factor: 0.075 !default;
$table-hover-bg: rgba($black, $table-hover-bg-factor) !default;

$table-variants: (
  "primary": table-color-map(shift-color($primary, $table-bg-scale)),
  "secondary": table-color-map(shift-color($secondary, $table-bg-scale)),
  "success": table-color-map(shift-color($success, $table-bg-scale)),
  "info": table-color-map(shift-color($info, $table-bg-scale)),
  "warning": table-color-map(shift-color($warning, $table-bg-scale)),
  "danger": table-color-map(shift-color($danger, $table-bg-scale)),
  "light": table-color-map($light, $body-bg),
  "dark": table-color-map($dark, $body-bg),
) !default;

// Toast

$toast-background-color: $bg-elevation-16 !default;
$toast-color: $body-color !default;
$toast-border-color: transparent !default;

$toast-header-color: $body-color !default;
$toast-header-background-color: $bg-elevation-24 !default;
$toast-header-border-color: rgba($black, 0.2) !default;

$utilities: (
  "bg": $theme-colors,
  "text": $theme-colors,
);

//NMA Variables

$nma-card-bg: $bg-elevation-1 !default;
$nma-card-border-color: #34353e !default;
