input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}
.nav-title {
  display: flex;
  align-items: center;
}
.space {
  margin: 8px;
}
.space-left {
  margin-left: 8px;
}
.space-right {
  margin-right: 8px;
}
.space-top {
  margin-top: 8px;
}
.space-bottom {
  margin-bottom: 8px;
}
.text-align-justify {
  text-align: justify;
}
.text-right {
  text-align: right;
}
.font-size-sm {
  line-height: 28px;
  font-size: 14px;
}
.font-size-md {
  line-height: 32px;
  font-size: 16px;
}
.font-size-lg {
  line-height: 36px;
  font-size: 18px;
}
.font-bold {
  font-weight: bold;
}
.font-italic {
  font-style: italic;
}
.responsive-image {
  object-fit: cover;
}
.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blured {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
  pointer-events: none;
}
.nma-brand {
  font-family: "Aquire";
  font-weight: bold;
  font-size: 20px;
}
.nma-alert {
  margin: 20px;
}
.dashboard-header {
  text-align: center;
  font-size: 1.1em;
  height: 40px;
  line-height: 40px;
  overflow-y: hidden;
}
.dashboard-stat {
  text-align: center;
  font-size: 1.8em;
  line-height: 60px;
  height: 60px;
  margin-bottom: 0px;
  overflow-y: hidden;
}
.dashboard-label {
  text-align: center;
  font-size: 0.9em;
  height: 40px;
  line-height: 40px;
  font-style: italic;
  margin-bottom: 0px;
  overflow-y: hidden;
}
.nma-form-container {
  @extend .form-control;
  height: auto !important;
  line-height: unset !important;
}
.nma-textarea {
  @extend .form-control;
  height: auto !important;
  line-height: unset !important;
  min-height: 120px !important;
  white-space: pre-line;
  display: inline-block;
}
.nma-shape {
  clip-path: polygon(
    0 0,
    calc(100% - 30px) 0%,
    100% 30px,
    100% calc(100% - 30px),
    100% 100%,
    30px 100%,
    0% calc(100% - 30px),
    0% 30px
  );
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 30px) 0%,
    100% 30px,
    100% calc(100% - 30px),
    100% 100%,
    30px 100%,
    0% calc(100% - 30px),
    0% 30px
  );
}
// Select
.nma-select {
  &-container {
    @extend .form-control;
    height: auto !important;
    padding-bottom: 0px !important;
  }
  &__control {
    background-color: transparent !important;
    border: 0px !important;
    color: white !important;
    &--is-focused {
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
    }
  }
  &__input {
    color: white !important;
  }
  &__value-container {
    padding: 0px !important;
  }
  &__single-value {
    color: white !important;
  }
  &__multi-value {
    background-color: $bg-elevation-16 !important;
  }
  &__multi-value__label {
    color: white !important;
  }
  &__menu {
    background-color: $bg-elevation-8 !important;
    z-index: 99 !important;
  }
  &__option {
    background-color: transparent !important;
    &--is-focused {
      background-color: $bg-elevation-16 !important;
    }
  }
}
// Phone Number
.nma-phone {
  &-container {
    @extend .form-control;
    height: auto !important;
    padding-bottom: 0px !important;
  }
  &-control {
    background-color: transparent !important;
    border: 0px !important;
    color: white !important;
    width: inherit !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-family: $font-family-sans-serif !important;
  }
  &-dropdown {
    background-color: $bg-elevation-8 !important;
    border: 0px !important;
    color: white !important;
    font-family: $font-family-sans-serif !important;
  }
  &-dropdown .country:hover {
    background-color: $bg-elevation-16 !important;
  }
  &-dropdown .country.highlight {
    background-color: $bg-elevation-16 !important;
  }
  &-button {
    background-color: transparent !important;
    border: 0px !important;
  }
  &-button .selected-flag {
    background-color: transparent !important;
  }
}
// NMA Card
.nma-card,
.nma-card-left,
.nma-card-right {
  position: relative;
  height: 100%;
}
.nma-card-body {
  padding: 1.5rem 1.5rem;
}
.nma-card::after {
  content: "";
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  clip-path: polygon(
    0 0,
    calc(100% - 30px) 0%,
    100% 30px,
    100% calc(100% - 30px),
    100% 100%,
    30px 100%,
    0% calc(100% - 30px),
    0% 30px
  );
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 30px) 0%,
    100% 30px,
    100% calc(100% - 30px),
    100% 100%,
    30px 100%,
    0% calc(100% - 30px),
    0% 30px
  );
  background-color: $nma-card-border-color;
}
.nma-card-body::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  clip-path: polygon(
    0 0,
    calc(100% - 30px) 0%,
    100% 30px,
    100% calc(100% - 30px),
    100% 100%,
    30px 100%,
    0% calc(100% - 30px),
    0% 30px
  );
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 30px) 0%,
    100% 30px,
    100% calc(100% - 30px),
    100% 100%,
    30px 100%,
    0% calc(100% - 30px),
    0% 30px
  );
  background-color: $nma-card-bg;
}
.nma-card-body-left {
  padding: 1.5rem calc(30px + 1.5rem) 1.5rem 1.5rem;
}
.nma-card-left::after {
  content: "";
  position: absolute;
  border-top-left-radius: 0.25rem;
  z-index: -2;
  top: 0;
  left: 0;
  right: -30px;
  bottom: 0;
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 60px) 0,
    calc(100% - 30px) 30px,
    calc(100% - 30px) calc(100% - 30px),
    100% 100%,
    30px 100%,
    0 calc(100% - 30px)
  );
  clip-path: polygon(
    0 0,
    calc(100% - 60px) 0,
    calc(100% - 30px) 30px,
    calc(100% - 30px) calc(100% - 30px),
    100% 100%,
    30px 100%,
    0 calc(100% - 30px)
  );
  background-color: $nma-card-border-color;
}
.nma-card-body-left::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 1px;
  left: 1px;
  right: -29px;
  bottom: 1px;
  border-radius: 0px;
  border-top-left-radius: 0.25rem;
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 60px) 0,
    calc(100% - 30px) 30px,
    calc(100% - 30px) calc(100% - 30px),
    100% 100%,
    30px 100%,
    0 calc(100% - 30px)
  );
  clip-path: polygon(
    0 0,
    calc(100% - 60px) 0,
    calc(100% - 30px) 30px,
    calc(100% - 30px) calc(100% - 30px),
    100% 100%,
    30px 100%,
    0 calc(100% - 30px)
  );
  background-color: $nma-card-bg;
}
.nma-card-body-right {
  padding: 1.5rem 1.5rem 1.5rem calc(30px + 1.5rem);
}
.nma-card-right::after {
  content: "";
  position: absolute;
  border: 0px;
  border-radius: 0px;
  border-bottom-right-radius: 00.25rem;
  z-index: -2;
  top: 0;
  left: -30px;
  right: 0;
  bottom: 0;
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 30px) 0,
    100% 30px,
    100% 100%,
    60px 100%,
    30px calc(100% - 30px),
    30px 30px
  );
  clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 30px, 100% 100%, 60px 100%, 30px calc(100% - 30px), 30px 30px);
  background-color: $nma-card-border-color;
}
.nma-card-body-right::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 1px;
  left: -29px;
  right: 1px;
  bottom: 1px;
  border-radius: 0px;
  border-bottom-right-radius: 0.25rem;
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 30px) 0,
    100% 30px,
    100% 100%,
    60px 100%,
    30px calc(100% - 30px),
    30px 30px
  );
  clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 30px, 100% 100%, 60px 100%, 30px calc(100% - 30px), 30px 30px);
  background-color: $nma-card-bg;
}
//Modal
.nma-modal .modal-content {
  background: black;
}
.fullname{
  white-space: pre-wrap;
  line-height: 1.2em;
}
@media (max-width: 767px) {
  .nma-card-body-left,
  .nma-card-body-right {
    padding: 1.5rem 1.5rem;
  }
  .nma-card-right::after {
    content: "";
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    clip-path: polygon(
      0 0,
      calc(100% - 30px) 0%,
      100% 30px,
      100% calc(100% - 30px),
      100% 100%,
      30px 100%,
      0% calc(100% - 30px),
      0% 30px
    );
    -webkit-clip-path: polygon(
      0 0,
      calc(100% - 30px) 0%,
      100% 30px,
      100% calc(100% - 30px),
      100% 100%,
      30px 100%,
      0% calc(100% - 30px),
      0% 30px
    );
    background-color: $nma-card-border-color;
  }
  .nma-card-body-right::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border-top-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    clip-path: polygon(
      0 0,
      calc(100% - 30px) 0%,
      100% 30px,
      100% calc(100% - 30px),
      100% 100%,
      30px 100%,
      0% calc(100% - 30px),
      0% 30px
    );
    -webkit-clip-path: polygon(
      0 0,
      calc(100% - 30px) 0%,
      100% 30px,
      100% calc(100% - 30px),
      100% 100%,
      30px 100%,
      0% calc(100% - 30px),
      0% 30px
    );
    background-color: $nma-card-bg;
  }
  .nma-card-left::after {
    content: "";
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    clip-path: polygon(
      0 0,
      calc(100% - 30px) 0%,
      100% 30px,
      100% calc(100% - 30px),
      100% 100%,
      30px 100%,
      0% calc(100% - 30px),
      0% 30px
    );
    -webkit-clip-path: polygon(
      0 0,
      calc(100% - 30px) 0%,
      100% 30px,
      100% calc(100% - 30px),
      100% 100%,
      30px 100%,
      0% calc(100% - 30px),
      0% 30px
    );
    background-color: $nma-card-border-color;
  }
  .nma-card-body-left::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border-top-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    clip-path: polygon(
      0 0,
      calc(100% - 30px) 0%,
      100% 30px,
      100% calc(100% - 30px),
      100% 100%,
      30px 100%,
      0% calc(100% - 30px),
      0% 30px
    );
    -webkit-clip-path: polygon(
      0 0,
      calc(100% - 30px) 0%,
      100% 30px,
      100% calc(100% - 30px),
      100% 100%,
      30px 100%,
      0% calc(100% - 30px),
      0% 30px
    );
    background-color: $nma-card-bg;
  }
}
